.region-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--primary);
    padding: 10px;
    height: 1.7em;
    font-size: 0.8em;
    margin-left: 1em;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary);
      //border-radius: 50%;
      border-radius: 30px;
      opacity: 0.2;
      z-index: 0;
  }


/*     width: auto;
    height: auto; */


}