@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");

@import "styles/reset";
@import "styles/variables";
@import "styles/utilities";
@import "styles/general";
@import "styles/grid";
@import "styles/form";
@import "styles/buttons";
@import "styles/toast";

@import "styles/page-layout";
@import "styles/ghost";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "styles/calendar/styles";

@import "components/components";

.header-banner-bar {
  position: sticky;
  top: 0px;
  display: flex;
  width: 100%;
  height: 50px;
  background-color: var(--primary);
  color: $white;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.App.has_banner_content {
  .global-header {
    top: 50px;
  }
  .global-sidebar {
    top: 110px;
    height: calc(100vh - 110px);
  }
  .warningBar{
    background-color: #FD1049;
  }

  .info-placeholder-wrapper {
    top: 115px !important;
  }
}

::-webkit-scrollbar {
  display: none;
  left: -60px;
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 50px;
  width: 10px;
  // background-color: $header-bg-color;
  border-left: 1px solid $border-color;
}

::-webkit-scrollbar-thumb {
  background-color: #22262e;
  background-clip: content-box;
  border-radius: 50px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
}



.player-logo {

    width: 50px;
    height: 50px;
    margin-right: 8px;
    border-radius: 16px;
    object-fit: cover;
}
.veto-timer {
  font: bold 12px Montserrat;
  text-transform: uppercase;
}
.slider {
  background-color: transparent !important;
  &::before{
    background-color: transparent !important;
  }
}