.fill-primary {
  svg {
    g {
      path {
        fill: var(--primary);
      }
    }
  }
}
.white-color-teams{
  img{
    filter: brightness(0) invert(1)
  }
}