@keyframes loading-pulses {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
  }
}

.column1 {
  padding-left: 14px;
  padding-right: 14px;
  flex: 0 0 11%;
  max-width: 11%;
}

.gs-gc-box {
  margin-left: 30px;
  .dropdown-toggle {
    padding: 5px 6px;
    width: 100%;

    .label {
      width: 63%;
    }
  }
  height: auto;
  width: 170px;
  position: relative;
  // background-color: var(--primary);
  border-radius: 0px;
  // background-image: linear-gradient(black,black);

  transition: all 0.5s ease;
  .gs-gc-top,
  .gs-gc-bottom,
  .gs-gc-left,
  .gs-gc-right,
  .gs-gc-left-corner,
  .gs-gc-right-corner {
    position: absolute;
    z-index: 1;
    border: 1px solid var(--primary); //this line will uncomment
    transition: all 0.5s ease;
    filter:brightness(0.5);
  }
  &:hover {
    .gs-gc-top,
    .gs-gc-bottom,
    .gs-gc-left,
    .gs-gc-right,
    .gs-gc-left-corner,
    .gs-gc-right-corner {
      border: 1px solid var(--primary); //this line will uncomment
      opacity: 1;
      filter:brightness(1);
    }
    .gotogameButton {
      opacity: 1 !important;
    }
    .coverOpacity {
      display: block !important;
    }
  }

  .gs-gc-top {
    width: calc(100% - 19px);
    height: 1px;
    top: -5px;
    left: 22px;
  }

  .gs-gc-bottom {
    width: calc(100% - 19px);
    height: 1px;
    bottom: -5px;
    right: 22px;
  }

  .gs-gc-left {
    height: calc(100% - 17px);
    width: 1px;
    bottom: -5px;
    left: -5px;
    z-index: -9;
  }

  .gs-gc-right {
    height: calc(100% - 17px);
    width: 1px;
    top: -5px;
    right: -5px;
    z-index: -9;
  }

  .gs-gc-left-corner {
    height: 38px;
    width: 1px;
    bottom: -10px;
    right: 8px;
    transform: rotate(45deg);
  }

  .gs-gc-right-corner {
    height: 38px;
    width: 1px;
    top: -10px;
    left: 8px;
    transform: rotate(45deg);
    z-index: 1;
  }
}

.frontend-gs-gc-box {
  margin-left: 30px;

  // height: auto;
  // width: 170px;
  // position: relative;
  // border-radius: 0px;
  .gs-gc-top,
  .gs-gc-bottom,
  .gs-gc-left,
  .gs-gc-right,
  .gs-gc-left-corner,
  .gs-gc-right-corner {
    position: absolute;
    z-index: 1;
    border: 1px solid var(--primary); //this line will uncomment
    filter:brightness(0.5);
  }

  &:hover {
    .gs-gc-top,
    .gs-gc-bottom,
    .gs-gc-left,
    .gs-gc-right,
    .gs-gc-left-corner,
    .gs-gc-right-corner {
      border: 1px solid var(--primary); //this line will uncomment
      transition: all 0.5s ease-in-out;
      filter:brightness(1);

    }
  }

  .gs-gc-top {
    width: calc(100% - 19px);
    height: 1px;
    top: -5px;
    left: 22px;
  }

  .gs-gc-bottom {
    width: calc(100% - 19px);
    height: 1px;
    bottom: -5px;
    right: 22px;
  }

  .gs-gc-left {
    height: calc(100% - 17px);
    width: 1px;
    bottom: -5px;
    left: -5px;
    z-index: -9;
  }

  .gs-gc-right {
    height: calc(100% - 17px);
    width: 1px;
    top: -5px;
    right: -5px;
    z-index: -9;
  }

  .gs-gc-left-corner {
    height: 38px;
    width: 1px;
    bottom: -10px;
    right: 8px;
    transform: rotate(45deg);
  }

  .gs-gc-right-corner {
    height: 38px;
    width: 1px;
    top: -10px;
    left: 8px;
    transform: rotate(45deg);
    z-index: 1;
  }
}

.coverOpacity {
  transition: opacity 0.5s ease;
  background-image: linear-gradient(black, black);
  opacity: 0;
  width: 100%;
  height: 100%;
  // display: none;
}

.item {
  transition: all 0.5s ease;
  min-width: 350px;
  .disabled_link,
  .disabled_link[disabled] {
    cursor: default !important;
    pointer-events: none;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .thumbnail-container {
    position: relative;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, .25);
    border-radius: 12px;
    z-index: 1;

    &::before, &::after {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      background-color: $content-bg-color;
      border: 1px solid rgba(255, 255, 255, .25);
      width: 200px;
      height: 200px;
      z-index: 2;
      pointer-events: none;
    }

    &::before {
      left: -155px;
      top: -155px;
    }

    &::after {
      right: -155px;
      bottom: -155px;
    }


    &:hover {
      .glitch__item:nth-child(n + 2) {
        opacity: 0;
        animation-duration: var(--time-anim);
        animation-delay: var(--delay-anim);
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
  
      .glitch__item:nth-child(2) {
        background-color: var(--blend-color-2);
        background-blend-mode: var(--blend-mode-2);
        animation-name: glitch-anim-2;
      }
      .glitch__item:nth-child(2) {
        background-color: var(--blend-color-3);
        background-blend-mode: var(--blend-mode-3);
        animation-name: glitch-anim-3;
      }
      .glitch__item:nth-child(4) {
        background-color: var(--blend-color-4);
        background-blend-mode: var(--blend-mode-4);
        animation-name: glitch-anim-4;
      }
  
      .glitch__item:nth-child(5) {
        background-color: var(--blend-color-5);
        background-blend-mode: var(--blend-mode-5);
        animation-name: glitch-anim-flash;
  
        @keyframes glitch-anim-flash {
          0%,
          5% {
            opacity: 0.2;
            transform: translate3d(var(--glitch-horizontal), var(--glitch-height), 0);
          }
  
          5.5%,
          100% {
            opacity: 0;
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
    
  }

  &.frontpage-item-collumn {
    padding: 3px;
    // flex: 0 0 31%;
    margin: 11px;

    &.gs-gc-box.frontpage-item-collumn {
      flex: 0 0 294px;
      min-width: 294px;
    }
    .gotogameButton {
      //   display: block !important;
      transition: all 0.5s ease;
      opacity: 0;
    }
    // border: 2px solid #22262e;
    // opacity: 0.4;
    &:hover {
      .coverOpacity {
        display: block;
      }
      // .frontpage-game-item {
      //   opacity: 0.4;
      //   background-image: linear-gradient(black,black);
      // }
      .bar {
        display: none;
      }
    }
  }
  padding-bottom: 36px;
  width: 350px;

  .item-image {
    width: 100%;
    transition: all 0.5s ease;
    &:hover {
      .browse-game {
        h3 {
          opacity: 1 !important;
        }
      }
      .coverOpacity {
        // display: block !important;
        transition: opacity 0.5s ease;
        opacity: 0.4 !important;
      }
    }

    &.browse-game-item {
      height: 300px;
      width: 172px;
      right: 3px;
      border: 3.5px solid transparent;
      .browser-game.gotogameButton {
        h3 {
          opacity: 0 !important;
        }
      }
      &:hover {
        border: 3.5px solid var(--primary);
      }
    }
    &:hover {
      .browse-game.gotogameButton {
        // display: block;
        opacity: 1 !important;
        z-index: 1 !important;
      }
    }

    &.frontpage-game-item {
      height: 250px;
      margin-bottom: 0px;
    }

    position: relative;
    height: 200px;
    // background-image: url(http://localhost:8080/image.jpg);
    background-size: cover;
    background-position: center center;
    // margin-bottom: 10px;
    overflow: hidden;

    .image-cover {
      position: absolute;
      transform: rotate(45deg);
      left: -160px;
      top: -160px;
      width: 200px;
      height: 200px;
      background-color: $content-bg-color;
    }

    .bottom-image-cover {
      position: absolute;
      transform: rotate(45deg);
      right: -160px;
      bottom: -160px;
      width: 200px;
      height: 200px;
      background-color: $content-bg-color;
    }

    span:not(.icon) {
      position: absolute;
      right:0;
      top: 15px;
      background-color: var(--primary);
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
      padding: 4px 8px;

      &:empty {
        display: none;
      }
    }

    .game-image-cover {
      position: absolute;
      right: 1px;
      bottom: 7px;
      padding: 4px 8px;

      &:empty {
        display: none;
      }
    }
  }
  .item-info {
    padding-top: 5px;

    span {
      /*display: inline-block;*/
      background-color: $border-color;
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      padding: 4px 9px;
    }
  }

  .item-title {
    &.frontpage-title {
      &.hover {
        .coverOpacity {
          // display: block !important;
          transition: opacity 0.5s ease;
          opacity: 0.4 !important;
        }
      }
      position: absolute;
      z-index: 1;
      width: 98%;
      padding-top: 1px;
      text-align: center;
      &.smallSlider {
        top: 31%;
      }
      &.gotogameButton {
        // display: none;
        top: 75%;
        transition: opacity 0.5s ease;

        h3 {
          color: var(--primary);
          opacity: 1;
          transition: opacity 0.5s ease;
          animation-name: fadeInOpacity;
          // animation-iteration-count: 1;
          animation-timing-function: ease;
          animation-duration: 2s;
        }
      }
    }

    h3 {
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      margin-top: 9px;
      margin-bottom: 8px;
    }
  }

  .item-excerpt {
    color: $text-faded-color;
    font-size: 12px;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .item-above-excerpt {
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 9px;
    color: $text-grey-color;

    img {
      display: inline;
      margin-right: 6px;
      height: 16px;
      vertical-align: middle;
    }
  }

  &.large {
    padding-bottom: 42px;

    .item-image {
      height: 450px;
    }

    .item-title {
      &.frontpage-title {
        position: absolute;
        z-index: 1;
        width: 100%;
        text-align: center;
      }
      h3 {
        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &.loading {
    animation: loading-pulses 1.2s infinite;

    .item-image {
      background-image: none !important;
      background-color: $text-faded-color;

      span {
        background-color: $content-bg-color;
        color: transparent;
      }
    }

    .item-info {
      span {
        color: transparent;
      }
    }

    .item-title {
      &.frontpage-title {
        position: absolute;
        z-index: 1;
        width: 100%;
        text-align: center;
      }
      h3 {
        // background-color: $text-faded-color;
        // color: transparent;
        width: 80%;
      }
    }

    // .item-excerpt {
      // color: transparent;
      // background-color: $text-faded-color;
    // }
  }
  &.frontpage-item-collumn {
    a:first-child::before {
      border-top: 26px solid rgb(3, 6, 13);
      border-left: 26px solid rgb(3, 6, 13);
    }
  }
  .item-info {
    span {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; 
    }
  }
}
.game-container {
  margin-left: 14px;
  margin-right: 14px;
  padding-right: 0px;
  padding-left: 0px;
  &:hover {
    border: 3.5px solid var(--primary);
  }
}
.browse-game {
  &.gotogameButton {
    // display: none;
    position: absolute;
    bottom: 0px;
    transition: all 0.5s ease;
    h3 {
      color: var(--primary);
      opacity: 0;
      transition: opacity 0.5s ease;
    }
  }
}

.headingGameCard:hover {
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition: 0.5s ease;
  width: 95%;
  height: 100% !important;
  border-top-left-radius: 50px 19px;
}


.gameSelection {
  .image-cover {
    border:none;
  }
}