@import "./MatchPageTabs/Games/games";

.match-page-league-nav-btn {
  margin-top: -30px;
  margin-bottom: 50px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-decoration: none;
  color: #999;

  svg {
    vertical-align: middle;
  }

  img {
    width: 35px;
    height: 35px;
    vertical-align: middle;
    border-radius: 4px;
  }
}

.get-codes {
  right: 0px;
  position: absolute;
  text-transform: uppercase;
  margin-top: -35px;
  font-weight: bold;
}

.matches-header-inner {
  margin: -15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .matched-header-left-team{
    text-align: right;
  }
  .matches-header-left-team,
  .matches-header-right-team {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    .imgCropped {
      width: 100px;
      height: 100px;
      margin: 20px;
      border-radius: 100%;
    }

    .imgNotCropped {
      width: 100px;
      height: 100px;
      margin: 20px;
    }

    h2 {
      margin: 6px 0px;
    }
  }

  .matches-header-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;

    .pill.large {
      text-transform: none;
      line-height: 22px;
      font-size: 18px;
      margin: 0px;
      font-weight: 500;
      letter-spacing: 1px;
      padding: 8px 12px;
      border-radius: 2px;
      margin: 10px 0px;
    }

    .match-live-timer {
      width: 130px;
    }
  }
}

.compare-players-tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // button {
  //   margin-top: -24px;
  // }
}

.navBtn {
  border-radius: 2px;
  size: 12px;
  text-transform: uppercase;
  padding: 8px;
  border: transparent;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin-top: -15px;
  &:disabled {
    cursor: not-allowed;
  }
}
.chooseSide {
  background-color: #3eabf0;
}

.newMatchDate {
  background-color: #68717e;
}

.your-matches-top-right-box {
  border: 1px solid $danger;
  border-radius: 2px;
  padding: 14px 22px;
}
.teamDetailsSpace {
  left: 7px;
}
.vsSpace {
  padding: "8px 12px";
}
.leagueDetailSpace {
  max-width: 305px;
}

.header-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // width: 264px;
  height: 200px;

  p {
    padding: 0px 0px 5px 0px;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
  }

  .badge-title {
    color: #68717e;
    font: normal normal bold 14px/18px Montserrat;
  }

  .badge-name {
    font: normal normal bold 14px/18px Montserrat;
    color: #ffffff;
  }
  .badge-image {
    width: 110px;
    height: 109px;
    padding-bottom: 7px;
  }

  .badge-division {
    color: #68717e;
    font: normal normal bold 14px/18px Montserrat;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &:hover {
    opacity: 0.6;
  }
}
.disabled-link {
  pointer-events: none;
}
