.dropdown-content{
    /* styles for dropdown-content element */
    
    &::-webkit-scrollbar {
      display: block !important;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #191b24;
      border: none;
    }
  
    &::-webkit-scrollbar-thumb {
        background-color: #22262e;
  
    }
  }